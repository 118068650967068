<template>
  <div class="integration-fonn view">
    <div class="integration-fonn__logo">
      <img src="https://fonn.io/assets/images/fonn_logo.svg" alt="fonn-logo" />
    </div>
    <component :is="stepComp" v-model="data" v-bind="$attrs" @navigate="$ev => (stepComp = $ev)" />
  </div>
</template>

<script>
import Steps from '@/components/Fonn/Steps'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  props: {
    step: {
      type: String,
      enum: ['units', 'fdv'],
      default: 'units',
    },
  },
  data() {
    return {
      data: {
        maps: {
          units: {},
          directories: {},
        },
      },
    }
  },
  computed: {
    stepComp: {
      get() {
        return this.step
      },
      set(step) {
        if (this.$route.params.step === step) return
        return this.$router.replace({ name: this.$route.name, params: { step }, query: this.$route.query })
      },
    },
  },
  components: {
    ...Steps,
    FontAwesomeIcon,
  },
}
</script>

<style lang="scss">
.integration-fonn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-grey;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 3rem;

    svg {
      opacity: 0.3;
    }

    img {
      max-height: 35px;
    }
  }

  .kvass-card {
    max-width: 750px;
    width: 100%;

    &__footer-content {
      justify-content: center;
      align-items: start;
    }
  }
}
</style>
