<template>
  <Card class="integration-fonn-units">
    <template #header>
      <slot name="header" />
    </template>
    <template #default>
      <Alert class="mb-1">
        Koble enheter i Kvass mot eksisterende prosjekter i Fonn ved å velge fra listen under eller la Kvass opprettet
        et nytt prosjekt i Fonn.
      </Alert>
      <Loader :value="promises.fetch" theme="overlay">
        <table>
          <tr>
            <th>Enhet i Kvass</th>
            <th>Prosjekt i Fonn</th>
          </tr>
          <tbody>
            <tr v-for="item in data.units" :key="item.value">
              <td>{{ item.label }}</td>
              <td>
                <SelectComponent
                  v-model="value.maps.units[item.value]"
                  :items="fonnUnits"
                  search
                  empty-label="Opprett nytt prosjekt eller velg eksisterende..."
                >
                  <template #noresults="{ filter }">
                    <div style="text-align: center">
                      Fant ingen resultater <template v-if="filter.search">for "{{ filter.search }}"</template>
                    </div>
                  </template>
                  <template #actions>
                    <div class="grow-1">
                      Listen viser resultater med søk: <b>{{ data.project }}</b
                      ><br />
                      Endre søket dersom du ikke finner riktig prosjekt.
                    </div>
                  </template>
                </SelectComponent>
              </td>
            </tr>
          </tbody>
        </table>
      </Loader>
    </template>
    <template #footer>
      <Alert v-if="hasDuplicateSelection" theme="error">Du har brukt et prosjekt i Fonn flere ganger.</Alert>
      <ButtonComponent
        :label="data.features.includes('fdv') ? $t('next') : $t('complete')"
        :icon="['fal', 'arrow-to-right']"
        theme="primary"
        :disabled="isDisabled"
        @click="submit"
        @onSuccess="onSubmit"
        :success-label="$t('completed')"
        :promise="promises.submit"
      />
    </template>
  </Card>
</template>

<script>
import Card from '@kvass/card'
import { ButtonComponent } from 'vue-elder-button'
import { LoaderComponent as Loader } from 'vue-elder-loader'
import { AlertComponent as Alert } from 'vue-elder-alert'
import SelectComponent from '@kvass/custom-fields/src/Fields/SelectModal'

const noDupes = arr => {
  return arr.every(x => arr.filter(u => u === x).length <= 1)
}

export default {
  props: {
    value: Object,
    token: String,
    account: String,
    returnTo: String,
    id: String,
  },
  data() {
    return {
      promises: {
        fetch: null,
        submit: null,
      },
      data: {
        features: [],
      },
    }
  },
  computed: {
    isDisabled() {
      return this.hasDuplicateSelection
    },
    hasDuplicateSelection() {
      return !noDupes(Object.values(this.value.map || {}).filter(Boolean))
    },
    fonnUnits() {
      return `https://${this.account}/api/integration/${this.id}/callbacks/projects?token=${this.token}&initialSearch=${this.data.project}`
    },
  },
  methods: {
    fetch() {
      this.promises.fetch = fetch(`https://${this.account}/api/integration/${this.id}/callbacks/unit-setup`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(res => {
          if (!res.ok) throw new Error()
          return res.json()
        })
        .then(({ map, ...rest }) => {
          this.value.maps.units = Object.fromEntries(rest.units.map(({ value: id }) => [id, map[id] || null]))
          this.data = rest
        })
    },
    submit() {
      if (this.isDisabled) return
      this.promises.submit = fetch(`https://${this.account}/api/integration/${this.id}/callbacks/unit-setup`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.value.maps.units),
      }).then(res => {
        if (!res.ok) throw new Error()
        return res.json()
      })
    },
    onSubmit() {
      if (this.data.features.includes('fdv')) return this.$emit('navigate', 'fdv')
      return (window.location.href = this.returnTo)
    },
  },
  created() {
    this.fetch()
  },
  components: {
    Loader,
    ButtonComponent,
    Card,
    Alert,
    SelectComponent,
  },
}
</script>

<style lang="scss">
.integration-fonn-units {
  table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 0.75rem;
      text-align: left;
    }

    tbody tr:nth-child(odd) {
      background-color: hsla(var(--border-color-h), var(--border-color-s), var(--border-color-l), 0.3);
    }
  }

  &__notset {
    font-style: italic;
    opacity: 0.7;
  }

  .alert p {
    margin-top: 0;
  }

  .kvass-selector {
    padding: 0.5em;
  }
}
</style>
