<template>
  <Card class="integration-fonn-fdv">
    <template #header>
      <slot name="header" />
    </template>
    <template #default>
      <Alert class="mb-1">
        Koble enheter i Kvass mot eksisterende documentmapper i Fonn ved å velge fra listen under eller la Kvass
        opprettet en ny mappe i Fonn.
      </Alert>
      <Loader :value="promises.fetch" theme="overlay">
        <table>
          <tr>
            <th>Enhet i Kvass</th>
            <th>Dokumentmappe i Fonn</th>
          </tr>
          <tbody>
            <tr v-for="item of data.units" :key="item.value">
              <td>{{ item.label }}</td>
              <td>
                <SelectComponent
                  v-model="value.maps.directories[item.value]"
                  :items="`https://${account}/api/integration/${id}/callbacks/directories?token=${token}&unit=${item.value}`"
                  empty-label="Opprett ny mappe eller velg eksisterende..."
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Loader>
    </template>
    <template #footer>
      <ButtonComponent
        :label="$t('complete')"
        :icon="['fal', 'arrow-to-right']"
        theme="primary"
        @click="submit"
        :promise="promises.submit"
      />
    </template>
  </Card>
</template>

<script>
import Card from '@kvass/card'
import { ButtonComponent } from 'vue-elder-button'
import { LoaderComponent as Loader } from 'vue-elder-loader'
import { AlertComponent as Alert } from 'vue-elder-alert'
import SelectComponent from '@kvass/custom-fields/src/Fields/SelectModal'

export default {
  props: {
    value: Object,
    token: String,
    account: String,
    returnTo: String,
    id: String,
  },
  data() {
    return {
      promises: {
        fetch: null,
        submit: null,
      },
      data: {},
    }
  },
  methods: {
    fetch() {
      this.promises.fetch = fetch(`https://${this.account}/api/integration/${this.id}/callbacks/fdv-setup`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(res => {
          if (!res.ok) throw new Error()
          return res.json()
        })
        .then(({ map, ...rest }) => {
          this.value.maps.directories = map || Object.fromEntries(rest.units.map(({ value: id }) => [id, null]))
          this.data = rest
        })
    },
    submit() {
      this.promises.submit = fetch(`https://${this.account}/api/integration/${this.id}/callbacks/fdv-setup`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.value.maps.directories),
      }).then(res => {
        if (!res.ok) throw new Error()
        return (window.location.href = this.returnTo)
      })
    },
  },
  created() {
    this.fetch()
  },
  components: {
    Loader,
    ButtonComponent,
    Card,
    Alert,
    SelectComponent,
  },
}
</script>

<style lang="scss">
.integration-fonn-fdv {
  table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 0.75rem;
      text-align: left;
    }

    tbody tr:nth-child(odd) {
      background-color: hsla(var(--border-color-h), var(--border-color-s), var(--border-color-l), 0.3);
    }
  }

  &__notset {
    font-style: italic;
    opacity: 0.7;
  }

  .alert p {
    margin-top: 0;
  }

  .kvass-selector {
    padding: 0.5em;
  }
}
</style>
